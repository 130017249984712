import React, { useContext, useEffect, useState } from "react";
import "./inquiryData.scss";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import EditNoteIcon from "@mui/icons-material/EditNote";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as XLSX from "xlsx";
import ReadMoreIcon from "@mui/icons-material/ReadMore";

const InquiryData = () => {
  const [data, setData] = useState([]);
  const { user } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(20); // Define how many rows you want to show per page
  const [id, setId] = useState("");
  const [show, setShow] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const [actionTaken, setActionTaken] = useState("");
  const [status, setStatus] = useState("");
  const [response, setResponse] = useState("");
  const [moreData, setMoreData] = useState("");

  // console.log(actionTaken, status,id);

  const nameData = user?.details.name;

  const popUp = (_id) => {
    setShow(true);
    setShowMore(true);
    setId(_id);
  };
  const popUpMore = (_id) => {
    setShowMore(true);
    setId(_id);
  };

  const popUpClose = () => {
    setShow(false);
    setShowMore(false);
    setActionTaken("");
    setStatus("");
  };

  // const devApiLink = "http://localhost:4348/api/auth";
  const apiLink = "https://mail-api.notionsgroup.com/api/auth";

  const getData = async () => {
    try {
      const response = await axios.get(`${apiLink}/getData`);
      // Sort data by date (descending)
      const sortedData = response.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setData(sortedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getUpdateData = async () => {
      if (!id) {
        return;
      }

      await axios.get(`${apiLink}/getData/${id}`).then((response) => {
        const { data } = response;
        setActionTaken(data.actionTaken);
        setStatus(data.status);
        setMoreData(response.data);
      });
    };
    getUpdateData();
  }, [id, show]);

  useEffect(() => {
    getData();
  }, [user, response]);

  // Helper function to format the date
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options); // 'en-GB' gives DD/MM/YYYY format
  };

  // Calculate the index of the first and last row for the current page
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentData = data.slice(indexOfFirstRow, indexOfLastRow);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Get the total number of pages
  const totalPages = Math.ceil(data.length / rowsPerPage);

  const updateInq = async (e) => {
    e.preventDefault();
    const data = {
      actionTaken,
      status,
      id,
    };
    try {
      await axios.put(`${apiLink}/update`, data).then((response) => {
        setResponse(response.data);
      });
      setShow("");
      setActionTaken("");
      setStatus("");
    } catch (error) {
      console.log(error);
    }
  };

  const truncateMessage = (message) => {
    const words = message?.split(" ");
    return words?.slice(0, 5).join(" ") + (words?.length > 5 ? "..." : "");
  };

  const truncateActionTaken = (actionTaken) => {
    if (!actionTaken) {
      return "";
    }

    const words = actionTaken.split(" ");
    return words.slice(0, 5).join(" ") + (words.length > 5 ? "..." : "");
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "closed":
        return "green";
      case "pending":
        return "red";
      default:
        return "black";
    }
  };

  // Export to Excel function
  const exportToExcel = () => {
    // Map data to required format
    const formattedData = data.map((item) => ({
      Date: new Date(item.createdAt).toLocaleDateString("en-GB"),
      Department: item.department || "",
      CustomerName: item.name || "",
      Message: item.message || "",
      EmailList: item.mailList?.join(", ") || "No emails",
      ActionTaken: item.actionTaken || "",
      Status: item.status || "",
    }));

    // Create a new workbook and worksheet
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Inquiry Data");

    // Trigger file download
    XLSX.writeFile(workbook, "InquiryData.xlsx");
  };

  return (
    <div className="__fw">
      <div className="__mw">
        {showMore && (
          <div className="popUp">
            <div className="popEdit">
              <div className="close" onClick={popUpClose}>
                <HighlightOffIcon />
              </div>
              <div className="popuInfo">
                <div className="moreInfo_wrp">
                  <h2 style={{ color: "orange" }}>
                    {moreData.department} <br />
                    {moreData.selectedOptionSales}
                  </h2>
                  <small>{formatDate(moreData.createdAt)}</small>
                  <div className="meg_wrp">
                    <p>{moreData.message}</p>
                  </div>
                  <div className="moreInfo_contact">
                    <p>
                      <strong>Name </strong> &nbsp;
                      {moreData.name} <br />
                      <strong>Email </strong> &nbsp; {moreData.email} <br />
                      <strong>Phone</strong> &nbsp;{moreData.phone} <br />
                      <strong> Country</strong> &nbsp;{moreData.inqCountry}
                    </p>
                  </div>
                  <div className="action">
                    <h4>Action Taken</h4>
                    <span style={{ color: getStatusColor(moreData.status) }}>
                      {moreData.status}
                    </span>
                    <div className="meg_wrp">
                      <p>{moreData.actionTaken}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {show && (
          <div className="popUp">
            <div className="popEdit">
              <div className="close" onClick={popUpClose}>
                <HighlightOffIcon />
              </div>
              <div className="popuInfo">
                <div className="popUpHead">
                  <h2>Update Inquiry Status </h2>
                  {/* <p>{id}</p> */}
                </div>
                <form onSubmit={updateInq}>
                  <div className="pop_radio">
                    <div>
                      <label>Pending</label>
                      <input
                        type="radio"
                        name="status"
                        value="pending"
                        checked={status === "pending"}
                        onChange={(e) => setStatus(e.target.value)}
                      />
                    </div>
                    <div>
                      <label>Closed</label>
                      <input
                        type="radio"
                        name="status"
                        value="closed"
                        checked={status === "closed"}
                        onChange={(e) => setStatus(e.target.value)}
                      />
                    </div>
                  </div>
                  <textarea
                    value={actionTaken}
                    onChange={(e) => setActionTaken(e.target.value)}
                    placeholder="Action Taken"
                  ></textarea>
                  <div className="submit">
                    <button className="btn" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        <div className="main_tb_wrp">
          <div className="data_table">
            <table>
              <thead className="tb_head">
                <tr>
                  <th style={{ width: "77px" }}>Date</th>
                  <th style={{ width: "120px" }}>Department</th>
                  <th style={{ width: "151px" }}>Customer Details</th>
                  <th style={{ width: "291px" }}>Message</th>
                  <th style={{ width: "41px" }}>More</th>
                  <th style={{ width: "183px" }}>Support User</th>
                  <th style={{ width: "237px" }}>Action Taken</th>
                  <th style={{ width: "60px" }}>Status</th>
                  {nameData === "admin" && <th>Edit</th>}
                </tr>
              </thead>

              <tbody>
                {currentData.map((item, i, _id) => (
                  <tr key={i}>
                    {/* Adjust index based on pagination */}
                    <td style={{ width: "77px" }}>
                      {formatDate(item.createdAt)}
                    </td>
                    <td style={{ width: "120px" }}>
                      {item.department === "Sales & Distribution Inquiries" ? (
                        <>{item.selectedOptionSales}</>
                      ) : (
                        <>
                          {item.department} <br />
                          {item.selectedOptionSales}
                        </>
                      )}
                    </td>
                    <td style={{ width: "151px" }}>
                      {item.name} <br />
                      {/* {item.email} <br />
                      {item.phone} <br />
                      {item.inqCountry} */}
                    </td>
                    <td style={{ width: "291px" }}>
                      {truncateMessage(item.message)}
                    </td>
                    <td
                      style={{ width: "41px" }}
                      className="icon"
                      onClick={() => popUpMore(item._id)}
                    >
                      <ReadMoreIcon />
                    </td>
                    <td style={{ width: "183px" }}>
                      {item.mailList && item.mailList.length > 0
                        ? item.mailList.map((mail, index) => (
                            <span key={index}>
                              {mail}
                              <br />
                            </span>
                          ))
                        : "No emails"}
                    </td>
                    <td style={{ width: "237px" }}>
                      {truncateActionTaken(item.actionTaken)}
                    </td>
                    <td
                      style={{
                        color:
                          item.status === "closed"
                            ? "green"
                            : item.status === "pending"
                            ? "red"
                            : "black",
                        width: "60px",
                      }}
                    >
                      {item.status}
                    </td>
                    {nameData === "admin" && (
                      <td className="icon" onClick={() => popUp(item._id)}>
                        <EditNoteIcon />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="export_button" >
              <button onClick={exportToExcel} style={{ marginBottom: "20px" }}>
                Export to Excel
              </button>
            </div>

            {/* Pagination Controls */}
            <div className="pagination">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>

              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}

              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquiryData;
